import React from 'react'
import LaffMoreHeader from './partials/LaffMoreHeader';
import LaffMoreFooter from './partials/LaffMoreFooter';
import { Outlet } from 'react-router-dom';

export default function BasePageLayout() {
    return (<site-wrapper>
        <LaffMoreHeader />
        <main className="main-content" role="main">
            <Outlet />
        </main>
        <LaffMoreFooter />
    </site-wrapper>)
}
