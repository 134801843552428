import {Route, Routes, useLocation} from 'react-router-dom'
import BasePageLayout from './page-layouts/BasePageLayout'
import InfoPageLayout from './page-layouts/InfoPageLayout';
import LaffMoreLayout from './page-layouts/LaffMoreLayout';
import { Suspense, lazy } from 'react'
import Redirect from '@scrippsproduct/scripps-external-component-library/dist/utils/Redirect'
import PageRouter from './utils/PageRouter'

const Home = lazy(() => import('./pages/Home'))
const Shows = lazy(() => import('./pages/Shows'))
const ShowDetails = lazy(() => import('./pages/ShowDetails'))
const Schedule = lazy(() => import('./pages/Schedule'))
const Sweeps = lazy(() => import('./pages/Sweeps'))
const SpecialLander = lazy(() => import('./pages/SpecialLander'))
const FindUs = lazy(() => import('./pages/FindUs'))
const Compliance = lazy(() => import('./pages/Compliance'))
const Sitemap = lazy(() => import('./pages/Sitemap'))  
const LaffMore = lazy(() => import('./pages/LaffMore')) 

const pageClassNames = new Map([
    ['/home', 'home-page'],
    ['/', 'home-page'],
    ['/shows', 'shows-page'],
    ['/show', 'show-details-page'],
    ['/movies', 'movies-page'],
    ['/movie', 'movie-details-page'],
    ['/follow', 'follow-page'],
    ['/special', 'special-page'],
    ['/sweeps', 'sweeps-page'],
    ['/schedule', 'schedule-page'],
    ['/find-us', 'find-us-page'],
    ['/gritxtra', 'gritxtra-page'],
    ['/privacy-center', 'privacy-center-page'],
    ['/privacy-contact', 'privacy-center-page'],
])

function Site() {
    const { pathname } = useLocation();
    
    return (
        <Suspense>
            <Routes>
                <Route exact path="/contact" element={<Redirect to="https://support.laff.com/support/home" />} />
                <Route exact path="/faqs" element={<Redirect to="https://support.laff.com/support/solutions" />} />
                <Route exact path="/findlaff" element={<Redirect to="/find-us" />} />
                <Route exact path="/findus" element={<Redirect to="/find-us" />} />
                <Route exact path="/special/follow" element={<Redirect to="/follow" />} />
                <Route path="/" element={<BasePageLayout pageClassname={pageClassNames.get(`/${pathname.split('/')[1]}`) ?? ''} />}>           
                    <Route index element={<Home />} key="home"/>
                    <Route exact path="/shows" element={<Shows />} key="shows"/>
                    <Route exact path="/show/:slug" element={<ShowDetails />} key="show-details"/>
                    <Route exact path="/follow" element={<SpecialLander slug="follow" />} key="special-lander" />
                    <Route exact path="/special/:slug" element={<SpecialLander />} key="special-lander"/>
                    <Route exact path="/sweeps/:slug" element={<Sweeps />} key="sweeps"/>
                    <Route exact path="/schedule" element={<Schedule />} key="schedule"/>
                    <Route exact path="/find-us" element={<FindUs />} key="find-us"/>
                    <Route exact path="/privacy-center" element={<Compliance docType="Privacy Center" slug={'privacy-center'} />} key="privacy-center"/>
                    <Route exact path="/privacy-contact" element={<Compliance docType="Privacy Center" slug={'privacy-center'} />} key="privacy-center"/>
                </Route>
                <Route path="/" element={<LaffMoreLayout />}>
                    <Route exact path="/laffmore" element={<LaffMore />} key="laffmore" />
                </Route>
                <Route path="/" element={<InfoPageLayout />}>
                    <Route exact path="/privacy-policy" element={<Compliance docType="Privacy Policy" slug="/privacy-policy" />} key="privacy-policy" />
                    <Route exact path="/privacy-policy/previous" element={<Compliance docType="Privacy Policy" slug="/previous-privacy-policy" previousVersion={true} />} key="previous-privacy-policy" />
                    <Route exact path="/terms-of-use" element={<Compliance docType="Terms of Use" slug="/networks-terms-of-use" />} key="terms-of-use" />
                    <Route exact path="/terms-of-use/previous" element={<Compliance docType="Terms of Use" slug="/previous-terms-of-use" previousVersion={true} />} key="previous-terms-of-use" />
                    <Route exact path="/sitemap" element={<Sitemap />} key="sitemap" />
                    <Route path="*" element={<PageRouter />} />
                </Route>
            </Routes>
        </Suspense>
    );
}

export default Site;
