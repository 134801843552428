import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { getResourceList } from '../../models/BaseModel'
import { ResponsiveImage, FooterNavigation, SocialLinks, BlankErrorBoundary } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Footer.scss'

export default function Footer() {
    const [navSections, setNavSections] = useState([])
    const [socialLinks, setSocialLinks] = useState([])
    const {isSuccess, data} = useQuery(['laffmore-footer', {resourceName: 'laffmore-footer'}], getResourceList)

    useEffect(() => {
        if (isSuccess) {
            setNavSections(data.get('navSections'))
            setSocialLinks(data.get('socialLinks'))
        }
    }, [isSuccess, data])

    return (
        <footer className="main-footer laffmore-main-footer">
            <content-constrainer>
                <div className="footer-logo">
                <ResponsiveImage                     
                    fullSizeUrl={`https://ewscripps.brightspotcdn.com/dims4/default/4028f74/2147483647/strip/true/crop/134x80+0+0/resize/134x80!/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F65%2Fe0%2Fb7be42e94ea89a5347f6c8dd9e45%2Flaff-more-logo.png`}
                    altText="Laff More Logo"
                    loading="eager"             
                />
                {/* <ImageReplacementAnchor 
                    href={`/laffmore`} 
                    image={{
                        url: `https://ewscripps.brightspotcdn.com/dims4/default/4028f74/2147483647/strip/true/crop/134x80+0+0/resize/134x80!/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F65%2Fe0%2Fb7be42e94ea89a5347f6c8dd9e45%2Flaff-more-logo.png`,
                        width: 300,
                        height: 119,
                    }}
                    height={4.6}
                    baselineAdjustment={-1.25}
                    text='Laff More'
                    traget='_self'
                /> */}
                </div>
                <div className="footer-nav-wrapper">
                    { (isSuccess && navSections.length > 0) && 
                        <BlankErrorBoundary>
                            <FooterNavigation sections={navSections} />
                        </BlankErrorBoundary> 
                    }
                    { (isSuccess && socialLinks.length > 0) && 
                        <BlankErrorBoundary>
                            <SocialLinks size={1.5} showHeader={true} platforms={socialLinks}/>
                        </BlankErrorBoundary> 
                    }
                </div>
                <div className="copyright-notice">
                    {`© ${new Date().getFullYear()} The E.W. Scripps Company, all rights reserved. All third-party trademarks, including logos, are the property of their respective owners.`}
                </div>
            </content-constrainer>
        </footer>
    )
}
