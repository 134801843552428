import React from 'react'
import Header from './partials/Header';
import Footer from './partials/Footer';
import { Outlet } from 'react-router-dom';

export default function InfoPageLayout() {
    return (<site-wrapper class="info-layout">
        <Header />
        <main className="main-content info-page page-section page-section--extra-margin" role="main">
            <content-constrainer class="content-constrainer--no-border content-constrainer--lead-container">
                <Outlet />
            </content-constrainer>
        </main>
        <Footer />
    </site-wrapper>)
}
