import { CtaBlock, GetAirdateWrapper } from "@scrippsproduct/scripps-external-component-library"
import { generateHrefFromProgramSlug, setAltText } from '@scrippsproduct/scripps-external-component-library/dist/utils/helpers'

const SCHEDULE_ENDPOINT = process.env.REACT_APP_SCHEDULE_V2
const NETWORK = process.env.REACT_APP_NETWORK
const BC_AID = process.env.REACT_APP_BC_ACCOUNTID
const BC_PLAYERID = process.env.REACT_APP_BC_PLAYERID
const BC_EMBEDID = process.env.REACT_APP_BC_EMBEDID

const setSlideImgUrl = (slide) => {
    return slide.image?.[0]?.image?.publicUrl || ''
}

const buildCta = (slide) => {
    const determineType = () => {
        if (slide.brightcoveID) return 'play-video'
        if (slide.program.length > 0 ) return 'show-modal'
        if (slide.linkType === "EXTERNAL") return 'external-link'
        if (slide.linkType === "INTERNAL") return 'internal-link'
    }
    const determineRelatedResourceId = () => {
        if (slide.brightcoveID) return slide.brightcoveID
        if (slide.program.length > 0) {
            const program = slide.program[0].item[0]
            if (program['__typename'] === 'SeriesItem') return program.seriesPrefix
            if (program['__typename'] === 'MovieItem') return program.titleCode
        }

        return null
    }

    const determineRelatedResourceIdType = () => {
        if (slide.brightcoveID) return 'video'
        if (slide.program.length > 0) {
            const program = slide.program[0].item[0]
            if (program['__typename'] === 'SeriesItem') return 'series' 
            if (program['__typename'] === 'MovieItem') return 'film'
        }
    }

    return {
        href: (slide.linkUrl && !slide.program.length > 0) ? slide.linkUrl : '',
        type: determineType(),
        target: slide.linkTarget,
        relatedResourceId: determineRelatedResourceId(),
        relatedResourceIdType: determineRelatedResourceIdType()
    }
}

const determineAspectRatio = ({slide, carouselType}) => {
    if (carouselType === 'BANNER') return 1710/850
    if (carouselType === 'FEATURED') return 395/583
    if (slide.image[0].image?.width && slide.image[0].image?.height) return slide.image[0].image.width/slide.image[0].image.height
    
    return 0.677966101694915
}

const transformShowSlideLayout = (slide, index, type = 'series') => {
    let program = {}
    let props = {}
    
    if (type === 'series') {
        program = slide.program?.[0]?.item?.[0] || {}
        props = {
            series: {
                title: slide.title,
                bannerImage: {
                    image: {
                        publicUrl: setSlideImgUrl(slide),
                        altText: setAltText(slide), // NOTE: This is being passed in correctly, but the ShowSlide layout is not set up to accept the alt text, it autopopulates it to ''. Will need to update the module in order for this to be able to be populated correctly.
                        sources: [
                            {
                                media: 'min-width: 1800px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.publicUrl}`,
                                ]
                            },
                            {
                                media: 'min-width: 1500px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                                    `${slide.image?.[0]?.image?.publicUrl} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1200px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 900px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 600px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 300px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                                ]
                            }
                        ]
                    }
                },
                bannerMobileImage: {
                    image: {
                        publicUrl: slide.mobileImage?.[0]?.image?.publicUrl || '',
                        altText: setAltText(slide),
                    },
                },
                logoImage: {
                    image: {
                        publicUrl: program.logoImage?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src || '',
                        width: program.logoImage?.[0]?.image?.width,
                        height: program.logoImage?.[0]?.image?.height,
                        sizes: [
                            {
                                srcSets: [
                                    {
                                        src: program.logoImage?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src || '',                                    }
                                ],
                            },
                        ],
                    },
                },
                programType: type,
                slug: program.slug || '',
                seriesPrefix: program.seriesPrefix || '',
            },
            titlePosition: slide.headlineAlignment,
            titleColor: slide.textColor,
            fullSizeImagePosition: [slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50],
            mobileImagePosition: [slide.imageAlignment?.[0]?.mobileImageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.mobileImageAlignmentYAxis ?? 50],
            lazyLoad: (index > 1) ? true : false,
            fetchPriority: (index > 1) ? 'low' : 'high',
            decoding: (index > 1) ? 'async' : 'sync',
            hasSeriesPages: false,
            handleClicks: eventDetails => {
                if (eventDetails.type === 'card-clicked' && eventDetails?.ctaType === 'show-modal') {  
                    const modalComponentProps = {
                        programId: eventDetails.resourceId,
                        programType: eventDetails.resourceType,
                    }
                    const event = new CustomEvent('scripps:upcomingmodal:change',  {
                        detail: {
                            componentProps: {
                                ...modalComponentProps,
                            },
                            forceOpen: false,
                        }
                    })
                    document.getElementById('upcoming-modal').dispatchEvent(event)
                    document.querySelector('body').style.overflow = 'hidden'
                }
            },
            NETWORK,
            SCHEDULE_ENDPOINT,
        }
    } else if (type === 'film') {
        program = slide.program?.[0]?.item?.[0] || {}
        props = {
            series: {
                title: slide.title,
                bannerImage: {
                    image: {
                        publicUrl: setSlideImgUrl(slide),
                        altText: setAltText(slide), // NOTE: This is being passed in correctly, but the ShowSlide layout is not set up to accept the alt text, it autopopulates it to ''. Will need to update the module in order for this to be able to be populated correctly.
                        sources: [
                            {
                                media: 'min-width: 1800px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.publicUrl}`,
                                ]
                            },
                            {
                                media: 'min-width: 1500px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                                    `${slide.image?.[0]?.image?.publicUrl} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1200px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 900px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 600px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 300px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                                ]
                            },
                            {
                                media: 'min-width: 1px',
                                srcsets: [
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                                    `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                                ]
                            }
                        ]
                    }
                },
                bannerMobileImage: {
                    image: {
                        publicUrl: slide.mobileImage?.[0]?.image?.publicUrl || '',
                        altText: setAltText(slide),
                    },
                },
                programType: type,
                slug: program.slug || '',
                titleCode: program.titleCode || '',
            },
            titlePosition: slide.headlineAlignment,
            titleColor: slide.textColor,
            fullSizeImagePosition: [slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50],
            mobileImagePosition: [slide.imageAlignment?.[0]?.mobileImageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.mobileImageAlignmentYAxis ?? 50],
            lazyLoad: (index > 1) ? true : false,
            fetchPriority: (index > 1) ? 'low' : 'high',
            decoding: (index > 1) ? 'async' : 'sync',
            hasSeriesPages: false,
            handleClicks: eventDetails => {
                if (eventDetails.type === 'card-clicked' && eventDetails?.ctaType === 'show-modal') {  
                    const modalComponentProps = {
                        programId: eventDetails.resourceId,
                        programType: 'film',
                    }
                    const event = new CustomEvent('scripps:upcomingmodal:change',  {
                        detail: {
                            componentProps: {
                                ...modalComponentProps,
                            },
                            forceOpen: false,
                        }
                    })
                    document.getElementById('upcoming-modal').dispatchEvent(event)
                    document.querySelector('body').style.overflow = 'hidden'
                }
            },
            NETWORK,
            SCHEDULE_ENDPOINT,
        }
    }
    
    return {
        layout: 'ShowSlide',
        props,
    }
};

const transformImageCardLayout = (slide, carouselType='') => {
    const determineContent = () => {
        if (carouselType === 'FEATURED') { 
            if (slide.program?.length > 0) {
                let programId = ''
                let programType = 'series'
                if (slide.program?.[0]?.item?.[0]?.seriesPrefix) {
                    programId = slide.program?.[0]?.item?.[0]?.seriesPrefix
                }
                if (slide.program?.[0]?.item?.[1]?.titleCode) {
                    programId = slide.program[0].item?.[1]?.titleCode
                    programType = 'film'
                }
                
                return <GetAirdateWrapper 
                    programId={programId}
                    programType={programType} 
                    separatorType={(window.matchMedia('(min-width: 1470px)').matches) ? 'pipe' : 'single-line-break'}
                    NETWORK={NETWORK}
                    SCHEDULE_ENDPOINT={SCHEDULE_ENDPOINT}
                />
            }
        }
        if (slide.headline  && carouselType === 'FEATURED') return slide.headline

        return ''
    }
    
    const determineRelatedResourceId = () => {
        if (slide.brightcoveID) return slide.brightcoveID
        if (slide.program.length > 0) {
            const program = slide.program[0].item[0]
            if (program['__typename'] === 'SeriesItem') return program.seriesPrefix
            if (program['__typename'] === 'MovieItem') return program.titleCode
        }

        return null
    }
    
    return {
        title: slide.title,
        layout: 'ImageCard',
        props: {
            id: slide.position,
            image: {
                url: setSlideImgUrl(slide),
                altText: setAltText(slide), 
                aspectRatio: determineAspectRatio({slide, carouselType}),
                sources: [
                    {
                        media: 'min-width: 1800px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.publicUrl}`,
                        ]
                    },
                    {
                        media: 'min-width: 1500px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 1x`, 
                            `${slide.image?.[0]?.image?.publicUrl} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 1200px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 900px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 600px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 300px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                        ]
                    },
                    {
                        media: 'min-width: 1px',
                        srcsets: [
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                            `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                        ]
                    }
                ],
            },
            mobileImage: {
                url: slide.mobileImage?.[0]?.image?.publicUrl || '',
                aspectRatio: slide.mobileImage[0]?.image?.width/slide.mobileImage[0]?.image?.height,
            },
            content: determineContent(),
            cta: buildCta(slide),
            fullCardClickable: true,
            relatedResourceId: determineRelatedResourceId(),
            fullSizeImagePosition: [slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50],
            mobileImagePosition: [slide.imageAlignment?.[0]?.mobileImageAlignmentXAxis ?? 50, slide.imageAlignment?.[0]?.mobileImageAlignmentYAxis ?? 50],
            lazyLoad: (carouselType === 'BANNER') ? false : true,
            handleClicks: eventDetails => {
                if (eventDetails.type === 'play-video' || (eventDetails.type === 'card-clicked' && eventDetails?.ctaType === 'play-video')) {  
                    const modalComponentProps = {
                        bcId: eventDetails.resourceId,
                        autoplay: true,
                        BC_AID,
                        BC_PLAYERID,
                        BC_EMBEDID,
                    }
                    const event = new CustomEvent('scripps:modalcontainer:change',  {
                        detail: {
                            componentProps: {
                                ...modalComponentProps,
                            },
                            forceOpen: true,
                        }
                    })
                    document.getElementById('modal-container').dispatchEvent(event)
                    document.querySelector('body').style.overflow = 'hidden'
                } else if (eventDetails.type === 'card-clicked' && eventDetails?.ctaType === 'show-modal') {  
                    console.log('eventDetails', eventDetails)
                    const modalComponentProps = {
                        programId: eventDetails.resourceId,
                        programType: eventDetails.resourceType,
                        SCHEDULE_ENDPOINT,
                        NETWORK
                    }
                    const event = new CustomEvent('scripps:upcomingmodal:change',  {
                        detail: {
                            componentProps: {
                                ...modalComponentProps,
                            },
                            forceOpen: false,
                        }
                    })
                    document.getElementById('upcoming-modal').dispatchEvent(event)
                    document.querySelector('body').style.overflow = 'hidden'
                }
            },
            overlayContent: (carouselType === 'BANNER' && slide.headline) ? slide.headline : '',
        }
    }
}

const transformTwoColumnSlideLayout = (slide) => ({
    title: slide.title, // NOTE: doesn't look like this is even being used anymore, can we omit? 
    layout: 'TwoColumnSlide',
    props: {
        columnOneContent: <CtaBlock 
            title={slide.title}
            content={slide.headline}
            cta={{
                label: slide.ctaLabel,
                ...buildCta(slide),
            }}
            titleImageUrl={slide.logoImage?.[0]?.image?.publicUrl || ''}
            type={buildCta(slide).type}
            btnGradientStartColor={slide.ctaGradientStartOverride || `#000`}
            btnGradientEndColor={slide.ctaGradientEndOverride || `#555`}
            ctaButtonStyle='flat'
            btnFlatColors={{
                bgColor: `transparent`,
                bgHoverColor: `var(--mono-color-100)`,
                color: `var(--mono-color-100)`,
                hoverColor: `var(--theme-color-100)`,
                borderColor: `var(--mono-color-100)`,
                borderHoverColor: `var(--mono-color-100)`
            }}    
            />,
            columnTwoContent: 
            <div 
                className="image-container" 
                style={{
                    "--image-position": `${slide.imageAlignment?.[0]?.imageAlignmentXAxis ?? 50}% ${slide.imageAlignment?.[0]?.imageAlignmentYAxis ?? 50}%`
                }}
            >
                <img 
                    src={slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[0]?.src} 
                    srcSet={`${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[0]?.src} 300w, ${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src} 600w, ${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[2]?.src} 900w, ${slide.image?.[0]?.image?.sizes?.[0]?.srcSets?.[1]?.src} 1000w`}
                    alt={setAltText(slide)} 
                />
            </div>
    },
});

const transformPromotion = (slide) => {
    const program = slide.program?.[0]?.item?.[0];
    // It's only going to be transformed into an href link if it's a series or movie type
    const programTypeValid = program?.slug && ["SeriesItem", "MovieItem"].includes(program.__typename);
    const cta = {
        type:
            slide.brightcoveID ? "play-video"
            : slide.linkType === "EXTERNAL" ? "external-link"
            : (slide.linkType === "INTERNAL" || slide.linkUrl || programTypeValid) && "internal-link",
        label: slide.ctaLabel,
        href: !slide.brightcoveID ? slide.linkUrl || generateHrefFromProgramSlug(program) : null,
        position: (slide.linkUrl || slide.brightcoveID || programTypeValid) && "end", // CTA will not show up if position isn't set to "end", so this logic prevents slides without links (of any type) from mistakenly displaying a non-functional link.
        relatedResourceId: slide.brightcoveID,
    };

    return {
        id: slide.position,
        image: {
            url: setSlideImgUrl(slide),
            altText: setAltText(slide),
            aspectRatio: determineAspectRatio({slide}),
            sources: [
                {
                    media: 'min-width: 2000px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[4].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 1800px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 1200px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[2].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[5].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 600px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[3].src} 2x`
                    ],
                },
                {
                    media: 'min-width: 1px',
                    srcsets: [
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[0].src} 1x`, 
                        `${slide.image?.[0]?.image?.sizes?.[0].srcSets?.[1].src} 2x`
                    ],
                },
            ]
        },
        title: slide.title,
        content: slide.body,
        cta,
    }
};

export {
    transformShowSlideLayout,
    transformImageCardLayout,
    transformTwoColumnSlideLayout,
    transformPromotion
}