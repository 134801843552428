import React, { useEffect, useRef } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { ResponsiveImage } from '@scrippsproduct/scripps-external-component-library'

import './../../scss/Header.scss'

export default function Header() {
    const mobileNavRef = useRef()  

    const handleScroll = useDebouncedCallback(() => {
        if (window.scrollY > 10) {
            mobileNavRef.current.classList.add('mobile-nav--shadow');
        } else {
            mobileNavRef.current.classList.remove('mobile-nav--shadow');
        }
        }, 300) 

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);
    
    return (
        <header className="laffmore-main-header">
            <content-constrainer>
                <ResponsiveImage                     
                    fullSizeUrl={`https://ewscripps.brightspotcdn.com/dims4/default/4028f74/2147483647/strip/true/crop/134x80+0+0/resize/134x80!/quality/90/?url=http%3A%2F%2Fewscripps-brightspot.s3.amazonaws.com%2F65%2Fe0%2Fb7be42e94ea89a5347f6c8dd9e45%2Flaff-more-logo.png`}
                    altText="Laff More Logo"
                    loading="eager"             
                />
            </content-constrainer>
        </header>
    )
}
