import { resources } from './../settings/ProdSettings'

const getResourceList = async ({queryKey}) => {
    const [_key, { resourceName }] = queryKey

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const data = resources.get(resourceName).data
            return resolve(data)
        }, 100)
    })
}

const getResource = async ({queryKey}) => {
    const [_key, { resourceName, resourceId, resourceIdAttribute = 'id' }] = queryKey

    return new Promise((resolve, reject) => {
        setTimeout(() => {
            const data = resources.get(resourceName).data.find(item => {
                return item[resourceIdAttribute] === resourceId
            })

            resolve(data)
        }, 100)
    });
}

export {
    getResourceList,
    getResource,
}